<template>
  <div class="account">
    <div class="box-filter">
      <StatusUpdateDados />
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>

    <div class="primary">
      <div class="box table">
        <HeaderGraphic :data="topAccount" />
        <div class="painel">
          <div class="graphic-horizontal">
            <div class="line" v-for="(item, index) in topAccount.items" :key="item.marca">
              <div class="title">{{ item.name }}</div>
              <div class="box-g">
                <div class="bg"></div>
                <div class="box-bar">
                  <p class="box-bar__title" v-if="index == 0">Receita</p>
                  <div>
                    <div class="bar" :style="'width: ' + item.percent + '%'"></div>
                    <div class="number">{{ item.valor_vendido }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box table --big">
        <div class="box__header">
          <HeaderGraphic :data="tableData" />
          <a class="btn-download" @click="downloadCSV()" download>Download CSV</a>
        </div>
        <div class="painel">
          <b-table :items="tableData.items"></b-table>
          <paginate
            v-model="currentPage"
            :page-count="account.accounts_last_page"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import StatusUpdateDados from "@/components/status-update-dados/StatusUpdateDados.vue";
import { getAccounts, getCSV } from "@/api/accounts";
import { numberToReal, numberToRealWitoutSymbol } from "@/utils/money";

export default {
  components: {
    HeaderGraphic,
    FilterDatepicker,
    StatusUpdateDados,
  },
  data() {
    return {
      marcas: {},
      perPage: 15,
      currentPage: 1,
      start: "",
      end: "",
    };
  },
  mounted() {
    this.loadData();
    this.$store.commit("accounts/SET_SHOW_ACCOUNTS", true);
  },
  computed: {
    ...mapState({
      account: state => state.accounts,
      accountActive: state => state.accounts.accountActive,
    }),
    topAccount() {
      const items = [];
      const size = 8;
      if (this.account.accounts.items) {
        this.account.accounts.items.data.slice(0, size).map(line => {
          const percent = (line.valor_vendido * 100) / this.account.accounts.items.data[0].valor_vendido;
          let valorReal = numberToReal(line.valor_vendido);
          items.push({
            name: line.name,
            valor_vendido: valorReal,
            percent,
          });
        });
      }

      let data = {
        title: {
          top: "",
          name: "Top Contas",
        },
        legenda: [
          {
            title: "Top 3",
            color: "#FD3064",
          },
          {
            title: "Outras",
            color: "#2c2c2c",
          },
        ],
        items,
      };
      return data;
    },
    tableData() {
      const items = [];
      if (this.account.accounts.items) {
        this.account.accounts.items.data.map(line => {
          items.push({
            Conta: line.name,
            pedidos: line.qtd_pedidos,
            "Maior Pedido (R$)": numberToRealWitoutSymbol(line.maior_pedido),
            "Menor Pedido (R$)": numberToRealWitoutSymbol(line.menor_pedido),
            "Total faturado (R$)": numberToRealWitoutSymbol(line.valor_vendido),
            "Ticket Médio (R$)": numberToRealWitoutSymbol(line.ticket_medio),
          });
        });
      }
      let data = {
        title: {
          top: "",
          name: "Detalhamento",
        },
        items,
      };
      return data;
    },
  },
  methods: {
    getAccounts,
    getCSV,
    downloadCSV() {
      this.getCSV(this.start, this.end);
    },
    loadData() {
      this.getAccounts(this.start, this.end, this.perPage, this.currentPage);
    },
    setDataFilter({ start, end }) {
      this.start = start;
      this.end = end;
      this.getAccounts(this.start, this.end, this.perPage, this.currentPage);
    },
  },
  watch: {
    currentPage(newPage) {
      this.currentPage = newPage;
      this.getAccounts(this.start, this.end, this.perPage, this.currentPage);
    },
    accountActive(data) {
      if (data) {
        this.loadData();
      }
    },
  },
};
</script>

<style lang="scss">
.account {
  table thead th {
    width: 120px;
  }
  .graphic-horizontal {
    .line {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      &:first-child {
        .title {
          padding: 30px 15px 8px 0;
        }
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .box-g {
          .box-bar {
            .bar {
              background-color: #fd3064;
            }
          }
        }
      }
      .title {
        flex: 1;
        // max-width: 150px;
        font-size: 16px;
        text-align: left;
        padding: 15px 15px 8px 0;
      }
      .box-g {
        flex: 1;
        width: 100%;
        .box-bar {
          > div {
            display: flex;
            align-items: center;
          }
          max-width: 700px;
          &__title {
            font-weight: 400;
            font-size: 16px;
            text-align: left;
            color: #747474;
            margin-bottom: 15px;
          }
          .bar {
            height: 20px;
            background-color: #2c2c2c;
            border-radius: 50px;
            min-width: 20px;
          }
          .number {
            margin-left: 15px;
            color: #2c2c2c;
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .box-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .categorias {
    height: 100%;
    .page-container {
      margin: 30px 0;
    }
  }

  .small {
    max-width: 600px;
    margin: 150px auto;
  }

  .primary {
    display: flex;
    flex-wrap: wrap;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    max-width: calc(50% - 7.5px);
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 18px;
    box-shadow: 0 3px 6px #c3d0e029;
    border-radius: 20px;
    height: 400px;
    &:nth-child(2) {
      margin-right: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    .painel {
      position: relative;
      height: 300px;
      display: block;
      overflow: hidden;
    }
    &.table {
      margin: 0;
      margin-left: 15px;
      height: auto;
      &:after {
        content: "";
        display: block;
      }

      &:first-child {
        max-width: calc(40% - 15px);
        margin: 0;
        margin-right: 15px;
        display: inline-table;
      }
      &:last-child {
        max-width: calc(60% - 15px);
      }

      .painel {
        height: auto;
        overflow: inherit;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .primary {
      flex-direction: column;
    }
    .box {
      &.table {
        max-width: 100%;
        margin-top: 30px;
        margin-left: 0;
        &:first-child {
          max-width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .graphic-horizontal {
      .line {
        .title {
          max-width: 80px;
          overflow: hidden;
          font-size: 14px;
        }
        .box-g {
          .box-bar {
            .number {
              font-size: 17px;
            }
          }
        }
      }
    }
    .box-filter {
      justify-content: flex-end;
    }

    .primary {
      flex-direction: column;

      .box {
        &.table {
          max-width: 100%;
          margin-top: 30px;
          margin-left: 0;
          padding: 25px;
          overflow-x: scroll;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
